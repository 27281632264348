import { redirect } from 'react-router-dom';
import { getUser  } from '../services/authService';
import { TokenService } from '../services/tokenService';

const authLoader = async ({ request }: { request: Request }) => {
    const token = TokenService.getAccessToken();
    if (!token) {
        const url = new URL(request.url);
        sessionStorage.setItem('redirectTo', url.pathname + url.search);
        return redirect('/login');
    }

    const user = await getUser();
    if (!user) {
        const url = new URL(request.url);
        sessionStorage.setItem('redirectTo', url.pathname + url.search);
        return redirect('/login');
    }

    return null;
};

export default authLoader;
