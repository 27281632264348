import React from 'react';
import CardGrid, { CardItem, HoverEffect, Alignment, CardType } from './positioning/CardGrid';
import { Components } from "../api/client";

type Fuse = Components.Schemas.Fuse;

interface FuseProps {
    fuse: Fuse;
    loading: boolean;
}

const FuseComponent: React.FC<FuseProps> = ({ fuse, loading }) => {
    
    // Map and sort chargepoints
    const cardsChargepoints: CardItem[] = fuse.chargepoints.map((chargepoint) => ({
        type: CardType.Default,
        title: chargepoint.desc,
        href: `/infrastructure/${chargepoint.uuid}`,
        loading: loading,
        titleFontSize: 'text-sm',
        alignment: Alignment.Center,
        fullWidth: true,
        hoverEffect: HoverEffect.Card,
        status: chargepoint.available ?? false
    })).sort((a, b) => Number(a.status) - Number(b.status));

    return (
        <div>
            {loading ? (
                <div>Loading fuse data...</div>
            ) : (
                <div className="mt-5">
                    <CardGrid items={cardsChargepoints} />
                </div>
            )}
        </div>
    )
}

export default FuseComponent;
