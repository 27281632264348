import { useEffect, useState } from 'react';
import { isDarkMode } from '../utils/utils';

const useDarkMode = () => {
  const [darkMode, setDarkMode] = useState<boolean>(isDarkMode());

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = () => {
      setDarkMode(mediaQuery.matches);
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  return darkMode;
};

export default useDarkMode;
