import { MdHomeFilled } from "react-icons/md";
import { RiChargingPile2Fill } from "react-icons/ri";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { IoMdCard } from "react-icons/io";
import { FaUserFriends } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

import { MenuItem } from './types';

export const useMenuItems = (): MenuItem[] => {
    const { t } = useTranslation();

    return [
        {
            label: t('home'),
            icon: <MdHomeFilled className="w-5 h-5 text-icon-lg transition duration-75 dark:text-icon-dk group-hover:text-enerando-green" />,
            link: '/',
        },
        {
            label: t('sessions'),
            icon: <BsFillLightningChargeFill className="w-5 h-5 text-icon-lg transition duration-75 dark:text-icon-dk group-hover:text-enerando-green" />,
            link: '/sessions',
        },
        {
            label: t('infrastructure'),
            icon: <RiChargingPile2Fill className="w-5 h-5 text-icon-lg transition duration-75 dark:text-icon-dk group-hover:text-enerando-green" />,
            link: '/infrastructure',
        },
        {
            label: t('cards'),
            icon: <IoMdCard className="w-5 h-5 text-icon-lg transition duration-75 dark:text-icon-dk group-hover:text-enerando-green" />,
            link: '/cards',
        },
        {
            label: t('users'),
            icon: <FaUserFriends className="w-5 h-5 text-icon-lg transition duration-75 dark:text-icon-dk group-hover:text-enerando-green" />,
            link: '/users',
        },
    ];
};

export const useNavItems = (): { label: string; link: string }[] => {
    const { t } = useTranslation();

    return [
        { label: t('About'), link: 'https://www.enerando.energy/ueber-uns' },
        { label: t('Contact'), link: 'https://www.enerando.energy/kontakt' },
    ];
};
