import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { PiFilePdfBold } from 'react-icons/pi';
import ExportButton from './ExportButton';

interface PdfExportButtonProps {
    filename: string;
    data: {
        transactionId: string;
        period: string;
        energy: number;
        time: string;
        tariff: number;
    };
}

const PdfExportButton: React.FC<PdfExportButtonProps> = ({ filename, data }) => {
    const generatePDF = () => {
        const doc = new jsPDF();

        doc.text("Receipt", 14, 20);
        (doc as any).autoTable({
            startY: 30,
            head: [['Transaction ID', 'Period', 'Energy', 'Time', 'Tariff', 'Net', 'VAT', 'Total']],
            body: [
                [
                    data.transactionId,
                    data.period,
                    `${data.energy} kWh`,
                    data.time,
                    `${data.tariff.toFixed(2)} €/kWh`,
                    `${(data.tariff * data.energy).toFixed(2)} €`,
                    `${(data.tariff * data.energy * 0.20).toFixed(2)} €`,
                    `${(data.tariff * data.energy * 1.20).toFixed(2)} €`
                ],
            ],
        });

        return doc.output('blob');
    };

    const infoText = 'This button allows you to export the receipt data in PDF format.';

    return (
        <ExportButton
            filename={filename}
            icon={PiFilePdfBold}
            onGenerateFile={generatePDF}
            mimeType="application/pdf"
            infoText={infoText}
        />
    );
};

export default PdfExportButton;
