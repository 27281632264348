import { useState, useEffect, Component } from "react";
import { getUserList } from "../services/apiService";
import { Components } from "../api/client";

type User = Components.Schemas.User;

const useUserList = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getUserList();
                setUsers(data);
            } catch (error) {
                setError('Failed to fetch users.');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    return { users, error, loading };
};

export default useUserList;