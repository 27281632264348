import React from 'react';

interface InputProps {
  id: string;
  type: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  label: string;
}

const Input: React.FC<InputProps> = ({ id, type, value, onChange, required = false, label }) => {
  return (
    <div className="relative z-0 w-full mb-6 group">
      <input
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        required={required}
        className="font-body font-normal block w-full px-3 py-2 text-sm text-txt-lg-subtitle bg-bg-lg-input border-0 rounded-2xl appearance-none dark:text-txt-dk-subtitle dark:bg-bg-dk-input focus:outline-none focus:ring-0 peer"
        style={{ paddingTop: '1.25rem', paddingBottom: '0.5rem' }} // Adjust padding here
        placeholder=" " // Add a placeholder to trigger peer-placeholder-shown
      />
      <label
        htmlFor={id}
        className="font-body font-normal absolute text-sm text-txt-lg-placeholder dark:text-txt-dk-placeholder duration-300 transform -translate-y-1/2 top-1/2 left-3 origin-[0] peer-focus:left-3 peer-focus:text-enerando-green peer-focus:dark:text-enerando-green peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-valid:top-3 peer-valid:scale-75 peer-valid:-translate-y-2 peer-focus:top-3 peer-focus:scale-75 peer-focus:-translate-y-2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-placeholder-shown:dark:text-txt-dk-placeholder"
      >
        {label}
      </label>
    </div>
  );
};

export default Input;
