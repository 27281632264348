import React, { useState, FormEvent } from 'react';
import { useAuth } from '../contexts/AuthContext';
import Card from './basics/Card';
import Input from './basics/Input';
import Button, { ButtonVariant } from './buttons/Button';

interface LoginFormProps {
    onLoginSuccess?: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLoginSuccess }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const { login } = useAuth();

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        try {
            const success = await login(username, password);
            if (!success) {
                setError('Login failed. Please check your credentials.');
            } else if (onLoginSuccess) {
                onLoginSuccess();
            }
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('An unexpected error occurred.');
            }
        }
    };

    return (
        <Card title="Login" description="Please enter your username and password to log in.">
            <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">
                <form onSubmit={handleSubmit} className="w-full flex flex-col items-center">
                    <div className="w-full max-w-sm">
                        <Input
                            id="username"
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            label="Username"
                        />
                    </div>
                    <div className="w-full max-w-sm mt-2">
                        <Input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            label="Password"
                        />
                    </div>
                    {error && <div className="mb-6 text-red-600">{error}</div>}
                    <div className="w-full max-w-sm mt-4 flex justify-end">
                        <Button text="Submit" variant={ButtonVariant.Default} type="submit" />
                    </div>
                </form>
            </div>
        </Card>
    );
};

export default LoginForm;
