import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { FaClock } from "react-icons/fa";

interface SessionTimeProps {
    startTime?: Date;
    endTime?: Date;
    status?: string;
    loading: boolean;
}

const formatDateTime = (date: Date | undefined) => {
    if (!date) return { date: '', time: '' };
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
    return {
        date: date.toLocaleDateString(undefined, options),
        time: date.toLocaleTimeString(undefined, timeOptions)
    };
};

const SessionTime: React.FC<SessionTimeProps> = ({ startTime, endTime, status, loading }) => {
    const start = formatDateTime(startTime);
    const end = endTime ? formatDateTime(endTime) : null;

    if (loading) {
        return (
            <div className="flex flex-col items-center mb-6">
                <ol className="relative border-l border-border-lg dark:border-border-dk">
                    <li className="mb-10 ml-6">
                        <Skeleton circle={true} height={24} width={24} className="absolute -left-3" />
                        <h3 className="flex items-center mb-1 text-lg font-body font-bold text-txt-lg-header dark:text-txt-dk-header">
                            <Skeleton width={100} />
                        </h3>
                        <time className="block mb-2 text-sm font-body font-normal leading-none text-txt-lg-placeholder dark:text-txt-dk-placeholder">
                            <Skeleton width={150} />
                        </time>
                    </li>
                    <li className="ml-6">
                        <Skeleton circle={true} height={24} width={24} className="absolute -left-3" />
                        <h3 className="flex items-center mb-1 text-lg font-body font-bold text-txt-lg-header dark:text-txt-dk-header">
                            <Skeleton width={100} />
                        </h3>
                        <time className="block mb-2 text-sm font-body font-normal leading-none text-txt-lg-placeholder dark:text-txt-dk-placeholder">
                            <Skeleton width={150} />
                        </time>
                    </li>
                </ol>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center mb-6">
            <ol className="relative border-l border-border-lg dark:border-border-dk">
                <li className="mb-10 ml-6">
                    <span className="absolute flex items-center justify-center w-6 h-6 bg-bg-lg-content rounded-full -left-3 ring-7 ring-border-lg dark:ring-border-dk dark:bg-bg-dk-content">
                        <FaClock className="w-5 h-5 text-enerando-green" />
                    </span>
                    <h3 className="flex items-center mb-1 text-lg font-body font-bold text-txt-lg-header dark:text-txt-dk-header">
                        start {start.time}
                    </h3>
                    <time className="block mb-2 text-sm font-body font-normal leading-none text-txt-lg-placeholder dark:text-txt-dk-placeholder">
                        {start.date}
                    </time>
                </li>
                {status === 'stop' && end && (
                    <li className="ml-6">
                        <span className="absolute flex items-center justify-center w-6 h-6 bg-bg-lg-content rounded-full -left-3 ring-7 ring-border-lg dark:ring-border-dk dark:bg-bg-dk-content">
                            <FaClock className="w-5 h-5 text-enerando-green" />
                        </span>
                        <h3 className="flex items-center mb-1 text-lg font-body font-bold text-txt-lg-header dark:text-txt-dk-header">
                            end {end.time}
                        </h3>
                        <time className="block mb-2 text-sm font-body font-normal leading-none text-txt-lg-placeholder dark:text-txt-dk-placeholder">
                            {end.date}
                        </time>
                    </li>
                )}
            </ol>
        </div>
    );
};

export default SessionTime;