import React, { useState } from 'react';
import { IconType } from 'react-icons';
import { useTranslation } from 'react-i18next';

interface ExportButtonProps {
    filename: string;
    icon: IconType;
    onGenerateFile: () => BlobPart;
    mimeType: string;
    infoText?: string;
}

const ExportButton: React.FC<ExportButtonProps> = ({ filename, icon: Icon, onGenerateFile, mimeType, infoText }) => {
    const [isHovered, setIsHovered] = useState(false);
    const { t } = useTranslation();

    const downloadFile = () => {
        const element = document.createElement("a");
        const file = new Blob([onGenerateFile()], { type: mimeType });
        element.href = URL.createObjectURL(file);
        element.download = filename;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        document.body.removeChild(element); // Clean up after click
    };

    return (
        <div 
            onClick={downloadFile} 
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)} 
            style={{ display: 'inline-flex', cursor: 'pointer', position: 'relative' }}
        >
            <Icon className="w-7 h-7 text-icon-lg transition duration-75 dark:text-icon-dk hover:text-enerando-green" />
            {isHovered && infoText && (
                <div className="absolute z-10 inline-block text-sm mt-2 w-44 bg-bg-lg-item divide-y divide-bg-lg-content rounded-lg shadow dark:bg-bg-dk-item dark:divide-bg-dk-content p-3" style={{ top: '100%', left: '50%', transform: 'translateX(-50%)', whiteSpace: 'pre-wrap' }}>
                    {t(infoText)}
                </div>
            )}
        </div>
    );
};

export default ExportButton;
