import { useTranslation } from 'react-i18next';
import useUserList from '../hooks/useUserlist';

// components
import Heading from '../components/typography/Heading';
import Table, { TableColumn, TableRow } from '../components/basics/table';


const UsersPage: React.FC = () => {
    const { users, error, loading } = useUserList();
    const { t } = useTranslation();

    const userColumns: TableColumn[] = [
        { header: t("email"), key: "email" },
        { header: t("name"), key: "name" },
        { header: t("tenant"), key: "tenant" },
        { header: t("prioritized charging"), key: "prio" },
    ];

    // change to actual error component
    if (error) {
        return <div>{error}</div>;
    }

    const tableData: TableRow[] = users.map(user => {
        let name;
        if (user.first_name && user.last_name) {
            name = `${user.first_name} ${user.last_name}`;
        } else if (user.first_name) {
            name = user.first_name;
        } else if (user.last_name) {
            name = user.last_name;
        } else {
            name = 'N/A';
        }

        return {
            email: user.email,
            name: name,
            tenant: user.tenant?.name || 'N/A',
            prio: user.prio ? "Yes" : "No"
        };
    });

    return (
        <div>
            <Heading 
                title={t('Users')} 
                highlight={t('Your')} 
                description={t('All the Charging Cards.')}
            />
            <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">

                <Table columns={userColumns} data={tableData} loading={loading}/>

            </div>
        </div>
    );
};

export default UsersPage;