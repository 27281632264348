import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import authLoader from './authLoader';

// pages
import Home from '../pages/Home';
import ChargingSessionsPage from '../pages/ChargingSessionsPage';
import ChargingSessionDetailsPage from '../pages/ChargingSessionsDetailsPage';
import NotFound from '../pages/NotFound';
import BaseMenu from '../components/menu/BaseMenu';
import Login from '../pages/Login';
import Infrastructure from '../pages/Infrastructure';
import StationPage from '../pages/StationPage';
import SitePage from '../pages/SitePage';
import CardsPage from '../pages/CardsPage';
import UsersPage from '../pages/UsersPage';

// TODO: change routes for infrastructure to have site, pool, fuse, charegpoint logic?


const router = createBrowserRouter([
    {
        path: '/',
        element: <BaseMenu />,
        children: [
            {
                index: true,
                element: <Home />,
                loader: authLoader, // redirect to login
            },
            {
                path: 'sessions',
                element: <ChargingSessionsPage />,
                loader: authLoader, // redirect to login
            },
            {
                path: 'sessions/:id',
                element: <ChargingSessionDetailsPage />,
                loader: authLoader, // redirect to login
            },
            {
                path: 'infrastructure',
                element: <Infrastructure />,
                loader: authLoader, // redirect to login
            },
            {
                path: 'infrastructure/:id',
                element: <StationPage />,
                loader: authLoader, // redirect to login
            },
            {
                path: 'site',
                element: <SitePage/>,
                loader: authLoader, // redirect to login
            },
            {
                path: 'cards',
                element: <CardsPage/>,
                loader: authLoader, // redirect to login
            },
            {
                path: 'users',
                element: <UsersPage/>,
                loader: authLoader, // redirect to login
            },
            {
                path: 'login',
                element: <Login />,
            },
            {
                path: '*',
                element: <NotFound />,
            },
        ],
    },
]);

export default router;
