import { useState, useEffect } from "react";
import { getCardsList } from "../services/apiService";
import { Components } from "../api/client";

type Card = Components.Schemas.Card;

const useCardsList = () => {
    const [cards, setCards] = useState<Card[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getCardsList();
                setCards(data);
            } catch (error) {
                setError('Failed to fetch cards.');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    return { cards, error, loading };
};

export default useCardsList;