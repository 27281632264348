import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

/*
* For future pages or components, do not use the AttributeCard. Instead, use the Card component.
* The goal is to update the Card component to handle different desig choice including what the
* AttributeCard does. 
*/


interface AttributeCardProps {
    value?: string;
    name?: string;
    icon?: React.ReactNode;
    loading: boolean;
    children?: React.ReactNode;
}

const AttributeCard: React.FC<AttributeCardProps> = ({ value, name, icon, loading, children  }) => {
    return (
        <div className="flex flex-col items-center p-6 bg-bg-lg-content rounded-2xl shadow-sm dark:bg-bg-dk-content w-full">
            {loading ? (
                <Skeleton circle={true} height={50} width={50} className="mb-2" />
            ) : (
                icon && <div className="mb-2">{icon}</div>
            )}
            {loading ? (
                <Skeleton height={30} width={100} className="mb-1" />
            ) : (
                value && <h5 className="mb-1 text-2xl font-body font-bold tracking-tight text-txt-lg-header dark:text-txt-dk-header overflow-wrap break-words text-center">{value}</h5>
            )}
            {loading ? (
                <Skeleton width={80} />
            ) : (
                name && <p className="font-body font-normal text-txt-lg-body dark:text-txt-dk-body text-sm overflow-wrap break-words text-center">{name}</p>
            )}
            {children}
        </div>
    );
};


export default AttributeCard;
