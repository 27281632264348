// CollapsedContent.jsx
import React, { useState } from 'react';

interface DropdownProps {
    title: string;
    children: React.ReactNode;
}

const CollapsedContent: React.FC<DropdownProps> = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="mb-4">
            <h2>
                <button
                    type="button"
                    onClick={toggleDropdown}
                    className="flex items-center justify-between w-full py-5 font-medium rtl:text-right text-txt-lg-body border-b border-border-lg dark:border-border-dk dark:text-txt-dk-body gap-3"
                    aria-expanded={isOpen}
                >
                    <span>{title}</span>
                    <svg
                        className={`w-3 h-3 transition-transform ${isOpen ? 'rotate-180' : ''}`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5 5 1 1 5"
                        />
                    </svg>
                </button>
            </h2>
            {isOpen && (
                <div className="py-5 border-b border-border-lg dark:border-border-dk">
                    {children}
                </div>
            )}
        </div>
    );
};

export default CollapsedContent;
