import React from 'react';
import { formatDate } from '../utils/utils';
// add skeleton here too? or handled by card?

interface ReceiptProps {
    transactionId: string;
    period: string;
    energy: number;
    time: string;
    tariff: number;
}


const Receipt: React.FC<ReceiptProps> = ({ transactionId, period, energy, time, tariff }) => {
    const [startDate, endDate] = period.split(' - ');
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    if (!formattedStartDate || !formattedEndDate) {
        return null; // or return an error message if preferred
    }

    const vatRate = 20;
    const net = tariff * energy;
    const vat = net * (vatRate / 100);
    const total = net + vat;

    return (
        <dl className="divide-y divide-gray-100 py-3 last:pb-0">
            <table className="font-mono text-sm text-txt-lg-body dark:text-txt-dk-body w-full">
                <tbody>
                    <tr>
                        <td colSpan={2} className="font-semibold text-center">Company Name</td>
                    </tr>
                    <tr className="h-2"></tr>
                    <tr>
                        <td colSpan={2} className="text-center">123 Street Name</td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="text-center">City, State, ZIP</td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="text-center">Country</td>
                    </tr>
                    <tr className="h-2"></tr>
                    <tr>
                        <td colSpan={2} className="text-center">VAT ID: 123456789</td>
                    </tr>
                    <tr className="h-8"></tr>
                    <tr>
                        <td className="font-semibold">Transaction:</td>
                        <td className="text-right">{transactionId}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold">Period:</td>
                        <td className="text-right">{`${formattedStartDate} – ${formattedEndDate}`}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold">Energy:</td>
                        <td className="text-right">{energy} kWh</td>
                    </tr>
                    <tr>
                        <td className="font-semibold">Time:</td>
                        <td className="text-right">{time}</td>
                    </tr>
                    <tr className="h-2"></tr>
                    <tr>
                        <td className="font-semibold">Tariff:</td>
                        <td className="text-right">{tariff.toFixed(2)} € / kWh</td>
                    </tr>
                    <tr className="h-4"></tr>
                    <tr>
                        <td className="font-semibold">Net:</td>
                        <td className="text-right">{net.toFixed(2)} €</td>
                    </tr>
                    <tr>
                        <td className="font-semibold">VAT ({vatRate}%):</td>
                        <td className="text-right">{vat.toFixed(2)} €</td>
                    </tr>
                    <tr className="h-2"></tr>
                    <tr>
                        <td className="font-bold">Total:</td>
                        <td className="font-semibold text-right">{total.toFixed(2)} €</td>
                    </tr>
                </tbody>
            </table>
        </dl>
    );
};

export default Receipt;
