import { useState, useEffect } from 'react';
import { getChargingSessions } from '../services/apiService';
import { Components } from '../api/client';

// here could take type of ongoing and completed if different
type Session = Components.Schemas.Session;

const useChargingSessions = () => {
    const [ongoingSessions, setOngoingSessions] = useState<Session[]>([]);
    const [completedSessions, setCompletedSessions] = useState<Session[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getChargingSessions();

                setOngoingSessions(data.ongoing_sessions);
                setCompletedSessions(data.completed_sessions);
            } catch (error) {
                setError('Failed to fetch charging sessions.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { ongoingSessions, completedSessions, error, loading };
};

export default useChargingSessions;
