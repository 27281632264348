import React from 'react';
import CardGrid, { CardItem, CardType } from '../components/positioning/CardGrid';
import LoginForm from '../components/Login';
import Heading from '../components/typography/Heading';
import { ButtonVariant } from '../components/buttons/Button';
import { useAuth } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';


const Home: React.FC = () => {
  const { isAuthenticated, loading } = useAuth();
  const { t } = useTranslation();

  const cardItems = (t: any, isAuthenticated: boolean): CardItem[] => [
    {
      type: CardType.Default,
      title: t('Charging Sessions'),
      description: t('Current and historic session details.'),
      href: "/sessions",
      buttonText: t('See details'),
      buttonVariant: ButtonVariant.Default,
      isAuthenticated: isAuthenticated,
      requireAuth: true,
      loading: loading
    },
    {
      type: CardType.Default,
      title: t('Infrastructure'),
      description: t('All your charging stations at a glance.'),
      href: "/infrastructure",
      buttonText: t('See details'),
      buttonVariant: ButtonVariant.Default,
      loading: loading
    },
    {
      type: CardType.Default,
      title: t('Users'),
      description: t('Overview on users that are registered with your account.'),
      href: "/users",
      buttonText: t('See details'),
      buttonVariant: ButtonVariant.Default,
      loading: loading
    },
    {
      type: CardType.Default,
      title: t('Charging Cards'),
      description: t('Overview on RFID cards that are registered with your account.'),
      href: "/cards",
      buttonText: t('See details'),
      buttonVariant: ButtonVariant.Default,
      loading: loading
    },
  ];

  return (
    <div>
      <Heading 
        title={t('ENERANDO')} 
        highlight={t('Home')} 
        description={t('Your main dashboard with all your relevant energy & charging statistics on a glance.')}
      />
      {!isAuthenticated && (
        <div className="mb-10">
          <LoginForm />
        </div>
      )}
      <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">
        <CardGrid items={cardItems(t, isAuthenticated)} />
      </div>
    </div>
  );
};

export default Home;
