
class TokenService {
    private static ACCESS_TOKEN_KEY = 'accessToken';
    private static REFRESH_TOKEN_KEY = 'refreshToken';
  
    static setTokens(access: string, refresh: string) {
      localStorage.setItem(this.ACCESS_TOKEN_KEY, access);
      localStorage.setItem(this.REFRESH_TOKEN_KEY, refresh);
    }
  
    static getAccessToken() {
      return localStorage.getItem(this.ACCESS_TOKEN_KEY);
    }
  
    static getRefreshToken() {
      return localStorage.getItem(this.REFRESH_TOKEN_KEY);
    }
  
    static removeTokens() {
      localStorage.removeItem(this.ACCESS_TOKEN_KEY);
      localStorage.removeItem(this.REFRESH_TOKEN_KEY);
    }
  }

  export { TokenService };