import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Fix for default marker icon issue
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow
});

interface Location {
    id: number;
    name: string;
    position: [number, number];
}

interface MyMapProps {
    center: [number, number];
    locations: Location[];
    loading: boolean;
}

const LeafletMap: React.FC<MyMapProps> = ({ locations, center, loading }) => {
    return (
        <div className="map-container rounded-2xl shadow-sm overflow-hidden border border-border-lg dark:border-border-dk">
            {loading ? (
                <Skeleton height={400} width="100%" />
            ) : (
                <MapContainer center={center} zoom={15} style={{ height: '100%', width: '100%' }}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {locations.map((location) => (
                        <Marker key={location.id} position={location.position}>
                            <Popup>
                                <div className="text-txt-lg-body dark:text-txt-dk-body text-center">
                                    {location.name}
                                </div>
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
            )}
        </div>
    );
};

export default LeafletMap;
