import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/Login';
import Heading from '../components/typography/Heading';

const Login: React.FC = () => {
    const navigate = useNavigate();

    const handleLoginSuccess = () => {
        const redirectTo = sessionStorage.getItem('redirectTo') || '/';
        sessionStorage.removeItem('redirectTo');
        navigate(redirectTo);
    };

    return (
        <div>
            <Heading 
                title="ENERANDO" 
                highlight="Login" 
                description="Welcome to the ENERANDO Web App. Please log in to continue."
            />
            <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">
                <LoginForm onLoginSuccess={handleLoginSuccess} />
            </div>
        </div>
    );
};

export default Login;
