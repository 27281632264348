// SiteComponent.tsx
import React from 'react';
import { Components } from '../api/client';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import PoolComponent from './PoolComponent';


type Site = Components.Schemas.Site;

interface SiteProps {
    site: Site;
    loading: boolean;
}

const SiteComponent: React.FC<SiteProps> = ({ site, loading }) => {
    const renderStatusList = (fields: { label: string, value: boolean }[]) => {
        return (
            <li className="mb-2">
                {fields.map((field, index) => (
                    <span key={field.label} className="mr-4 font-body text-sm">
                        {field.label}: 
                        <span className={field.value ? 'text-enerando-green' : 'text-danger-base'}>
                            {field.value ? ' Enabled' : ' Disabled'}
                        </span>
                        {index < fields.length - 1 && ' | '}
                    </span>
                ))}
            </li>
        );
    };

    const pvSurplusChargingFields = [
        { label: 'PV Surplus Charging Enabled', value: site.pv_surplus_charging_enabled! },
        { label: 'Always Receive Minimum', value: site.pv_surplus_charging_always_receive_minimum! }
    ];

    const pvChargingFields = [
        { label: 'PV Charging Enabled', value: site.pv_charging_enabled! },
        { label: 'Always Receive Minimum', value: site.pv_charging_always_receive_minimum! }
    ];

    const peakLoadShavingFields = [
        { label: 'Peak Load Shaving Enabled', value: site.peak_load_shaving_enabled! },
        { label: 'Always Receive Minimum', value: site.peak_load_shaving_always_receive_minimum! }
    ];

    return (
        <div>
            {/* {loading ? (
                <Skeleton height={200} width="100%" />
            ) : (
                <ul className="list-disc pl-6 leading-tight space-y-1">
                    {renderStatusList(pvSurplusChargingFields)}
                    {renderStatusList(pvChargingFields)}
                    {renderStatusList(peakLoadShavingFields)}
                 </ul>
            )} */}

            <div className="mt-10 space-y-6">
                {site.pools.map((pool) => (
                    <PoolComponent key={pool.name} pool={pool} loading={loading} />
                ))}
            </div>
        </div>
    );
};

export default SiteComponent;
