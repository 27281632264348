import React from 'react';
import { Components } from "../api/client";
import FuseComponent from './FuseComponent';
import Container from './basics/Container';

type Pool = Components.Schemas.Pool;

interface PoolProps {
    pool: Pool;
    loading: boolean;
}

const PoolComponent: React.FC<PoolProps> = ({ pool, loading }) => {
    return (
        <Container 
            title={`Pool: ${pool.name}`} 
            // description={`Description for ${pool.name}`} 
            titlePosition="left"
            bgColor='color1'
        >
            {loading ? (
                <div>Loading pool data...</div>
            ) : (
                <div className="flex justify-center w-full">
                    <div className="flex flex-wrap justify-center w-full gap-6">
                        {pool.fuses.map((fuse) => (
                            <div key={fuse.name} className="flex-grow flex-basis-full sm:flex-basis-1/2 md:flex-basis-1/3 lg:flex-basis-1/4">
                                <Container 
                                    title={`Fuse: ${fuse.name}`}
                                    // description={`Load limit: ${pool.amps?.toString()} A`}
                                    titlePosition="left"
                                    bgColor='color2'
                                    className="w-full"
                                >
                                    <FuseComponent fuse={fuse} loading={loading} />
                                </Container>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </Container>
    );
};

export default PoolComponent;
