// Infrastructure.tsx
import { useTranslation } from 'react-i18next';
import useSites from '../hooks/useSites';
import Heading from '../components/typography/Heading';
import SiteComponent from '../components/SiteComponent';
import Container from '../components/basics/Container';

const Infrastructure: React.FC = () => {
  const { t } = useTranslation();
  const { sites, error, loading } = useSites();

  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Heading 
        title={t('overview')} 
        highlight={t('infrastructure')} 
        description={t('Add description of page')}
      />
      <div className='main-content mt-10 text-txt-lg-body dark:text-txt-dk-body'>
        <div className="space-y-6">
          {sites.map((site) => (
            <Container 
              key={site.uuid} 
              title={'Site: ' + site.name} 
              titlePosition="left"
              bgColor='color2'
            >
              <SiteComponent site={site} loading={loading} />
            </Container>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Infrastructure;
