import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { IoCarSportSharp } from "react-icons/io5";

interface StatusIndicatorProps {
    state: string;
    status: string;
    loading: boolean;
}

// change state and status here (wait for changes in ocpi)
const getStatusColor = (state: string | undefined) => {
    switch (state) {
        case 'charging':
            return 'from-charging-end to-charging-start';
        case 'stop':
            return 'border-enerando-green';
        case 'idle':
            return 'border-yellow-500';
        default:
            return 'border-enerando-gray';
    }
};

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ state, status, loading }) => {
    const statusColor = getStatusColor(state);

    if (loading) {
        return (
            <div className="flex flex-col items-center mb-6">
                <Skeleton circle={true} height={128} width={128} />
                <Skeleton width={100} height={24} className="mt-2" />
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center mb-6">
            <div className="relative flex items-center justify-center w-32 h-32">
                {state === 'charging' ? (
                    <div className={`absolute inset-0 rounded-full border-8 border-t-transparent border-r-transparent animate-variable-spin bg-gradient-to-r ${statusColor}`}></div>
                ) : (
                    <div className={`absolute inset-0 rounded-full border-8 ${statusColor}`}></div>
                )}
                <div className="absolute inset-2 bg-white dark:bg-black rounded-full z-10"></div>
                <IoCarSportSharp className="text-enerando-blue dark:text-hover-dk text-7xl z-20" />
            </div>
            <p className="mt-2 text-lg font-body font-normal text-sm text-center">{state}</p>
        </div>
    );
};

export default StatusIndicator;
