import { useTranslation } from 'react-i18next';

// components
import Heading from '../components/typography/Heading';

// This is intended to be a detail page for one sight given the site id
// should be accesable through link in title of site in infrastructure view

// IDEAS:
// Here could also add details settings for each site. Like change the way the site behaves in detail

// URL:
// update so that ../infrastructure/:id


const SitePage: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div>
            <Heading 
                title={t('sessions')} 
                highlight={t('Site')} 
                description={t('All the Charging Sessions.')}
            />
            {/* need to add site object and loading */}
            {/* <Site /> */}
        </div>
    );
};

export default SitePage;