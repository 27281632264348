import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export interface TableColumn {
    header: string;
    key: string;
    isEditable?: boolean;
}

export interface TableRow {
    [key: string]: string | number;
}

interface TableProps {
    columns: TableColumn[];
    data: TableRow[];
    pageSize?: number;
    onRowClick?: (row: TableRow) => void; // Optional row click handler
    loading: boolean;
}

const Table: React.FC<TableProps> = ({ columns, data, pageSize, onRowClick, loading }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = pageSize ? Math.ceil(data.length / pageSize) : 1;

    const handleClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const displayedData = pageSize
        ? data.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        : data;

    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-2xl">
            <table className="font-body font-normal w-full text-sm text-left rtl:text-right text-txt-lg-subtitle dark:text-txt-dk-subtitle">
                <thead className="font-body font-normal text-xs text-white uppercase bg-enerando-blue dark:bg-bg-dk-item dark:text-txt-dk-subtitle">
                    <tr>
                        {columns.map((column) => (
                            <th key={column.key} scope="col" className="px-6 py-3">
                                {loading ? <Skeleton width={100} /> : column.header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        Array.from({ length: pageSize || 5 }).map((_, rowIndex) => (
                            <tr key={rowIndex} className="bg-white border-border-lg dark:bg-bg-dk-content">
                                {columns.map((column) => (
                                    <td key={column.key} className="px-6 py-4">
                                        <Skeleton />
                                    </td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        displayedData.map((row, rowIndex) => (
                            <tr
                                key={rowIndex}
                                className="font-body font-normal bg-white border-border-lg dark:bg-bg-dk-content hover:bg-hover-lg dark:hover:bg-hover-dk"
                                onClick={() => onRowClick && onRowClick(row)} // Handle row click
                                style={{ cursor: onRowClick ? 'pointer' : 'default' }} // Change cursor to pointer if clickable
                            >
                                {columns.map((column) => (
                                    <td key={column.key} className="font-body px-6 py-4 font-normal text-txt-lg-body whitespace-nowrap dark:text-txt-dk-body">
                                        {row[column.key]}
                                    </td>
                                ))}
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            {pageSize && (
                <div className="flex justify-end mt-4">
                    <nav className="block">
                        <ul className="flex pl-0 rounded list-none">
                            {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                                <li key={page} className="first:ml-0 text-xs font-semibold flex mx-1">
                                    <button
                                        onClick={() => handleClick(page)}
                                        className={`flex items-center justify-center w-8 h-8 text-white bg-enerando-blue rounded-2xl focus:outline-none ${
                                            currentPage === page ? 'bg-enerando-green dark:bg-enerando-green' : ''
                                        }`}
                                    >
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            )}
        </div>
    );
};

export default Table;