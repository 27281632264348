import React from 'react';

export enum ButtonVariant {
  Default = 'default',
  Gradient = 'gradient',
  Outline = 'outline',
  Danger = 'danger',
}

interface ButtonProps {
  text: string;
  onClick?: () => void;
  className?: string;
  variant?: ButtonVariant;
  type?: 'button' | 'submit' | 'reset'; // Add this line to define the type property
}

const Button: React.FC<ButtonProps> = ({ text, onClick, className, variant = ButtonVariant.Default, type = 'button' }) => {
  // base design for all buttons
  const baseStyle = "relative inline-flex items-center justify-center text-sm font-medium rounded-2xl group focus:ring-2 focus:outline-none";

  const variants = {
    default: "px-4 py-2 text-white bg-enerando-green hover:bg-enerando-blue focus:ring-focus-lg dark:focus:ring-focus-dk",
    gradient: "px-4 py-2 text-white bg-gradient-to-r to-enerando-green from-enerando-blue hover:bg-gradient-to-bl focus:ring-hover-lg dark:focus:ring-focus-dk",
    outline: "p-0.5 overflow-hidden text-txt-lg-body bg-gradient-to-br from-enerando-blue to-enerando-green group-hover:from-enerando-blue group-hover:to-enerando-green hover:text-white dark:text-white focus:ring-focus-lg dark:focus:ring-focus-dk",
    danger: "px-4 py-2 text-white bg-danger-base hover:bg-danger-hover-lg dark:bg-danger-hover-bg focus:ring-focus-lg dark:focus:ring-focus-dk",
  };

  const innerSpanStyles = {
    outline: "relative px-3.5 py-1.5 transition-all ease-in duration-75 bg-bg-lg-content dark:bg-bg-dk-content rounded-2xl group-hover:bg-opacity-0 flex items-center justify-center",
  };

  const variantStyle = variants[variant];
  const innerSpanStyle = variant === ButtonVariant.Outline ? innerSpanStyles.outline : '';

  return (
    <button
      onClick={onClick}
      className={`${baseStyle} ${variantStyle} ${className}`}
      type={type}
    >
      {variant === ButtonVariant.Outline ? (
        <span className={innerSpanStyle}>
          {text}
          <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
          </svg>
        </span>
      ) : (
        <>
          {text}
          <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
          </svg>
        </>
      )}
    </button>
  );
};

export default Button;
