
import React from 'react';
import { JSONTree } from 'react-json-tree';

const lightTheme = {
    base00: '#FFFFFF', // bg.lg.content
    base01: '#c7c7cf',
    base02: '#b6b7c1',
    base03: '#8a8ea1',
    base04: '#5a607a',
    base05: '#07070d',
    base06: '#0a0b0e',
    base07: '#06070a',
    base08: '#06d98d',
    base09: '#0268e0',
    base0A: '#0b408a',
    base0B: '#004065', // enerando-blue
    base0C: '#5e101b',
    base0D: '#992610',
    base0E: '#517e00',
    base0F: '#3399cc',
  };
  
  const darkTheme = {
    base00: '#121212', // bg.dk.content
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    base05: '#f8f8f2',
    base06: '#f5f4f1',
    base07: '#f9f8f5',
    base08: '#f92672',
    base09: '#fd971f',
    base0A: '#f4bf75',
    base0B: '#44D62C', // enerando-green
    base0C: '#a1efe4',
    base0D: '#66d9ef',
    base0E: '#ae81ff',
    base0F: '#cc6633',
  };

  interface JSONTreeProps {
    invertTheme: boolean,
    data: any
}

const ThisJSONTree: React.FC<JSONTreeProps> = ({ invertTheme, data }) => {
    const theme = invertTheme ? lightTheme : darkTheme;
    return (
        <div>
            <JSONTree data={data} theme={theme} />
        </div>
    );
};

export default ThisJSONTree;



