import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ButtonVariant, default as Button } from '../buttons/Button';

interface LinkButtonProps extends LinkProps {
    text: string;
    variant?: ButtonVariant;
}

const LinkButton: React.FC<LinkButtonProps> = ({ text, variant = ButtonVariant.Default, ...linkProps }) => {
    return (
        <Link {...linkProps} className="inline-flex">
            <Button text={text} variant={variant} />
        </Link>
    );
};

export default LinkButton;