import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useMenuItems, useNavItems } from './menuItems';
import Navbar from './NavBar';
import Sidebar from './SideBar';
import Breadcrumb from '../menu/BreadCrumb';

import logoLight from '../../images/logos/enerando_logo_rgb_noclaim.png';
import logoDark from '../../images/logos/enerando_logo_rgb_wei├ƒ_noclaim.png';

const BaseMenu: React.FC = () => {
    const { loading } = useAuth();
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const menuItems = useMenuItems();
    const navItems = useNavItems();
    const navbarHeight = 64; // Assuming the navbar height is 64px, adjust as necessary
    const gapSize = 8;

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowWidth < 1024) {
            setSidebarOpen(false);
        } else {
            setSidebarOpen(true);
        }
    }, [windowWidth]);

    return (
        <div className="flex min-h-screen bg-bg-lg-bg dark:bg-bg-dk-bg">
            <Navbar 
                lightLogo={logoLight}
                darkLogo={logoDark}
                brandLink="/"
                menuItems={navItems}
                toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
                loading={loading}
                windowWidth={windowWidth} // Pass the windowWidth to Navbar
            />
            <Sidebar menuItems={menuItems} sidebarOpen={sidebarOpen} loading={loading} navbarHeight={navbarHeight} gapSize={gapSize} />
            <div className={`flex flex-col flex-1 transition-all duration-300 ${sidebarOpen ? 'lg:ml-64' : 'lg:ml-0'}`}>
                <div className="relative z-50"></div>
                <div className="p-4 mt-16 text-txt-lg-body dark:text-txt-dk-body">
                    <Breadcrumb />
                    <div className="mt-4">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BaseMenu;
