import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { logout, login, getUser, User } from '../services/authService';
import { TokenService } from '../services/tokenService';
import axios from 'axios';

interface AuthContextProps {
  isAuthenticated: boolean;
  loading: boolean;
  user: User | null;
  login: (username: string, password: string) => Promise<boolean>;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const initializeAuth = async () => {
      setLoading(true);
      const token = TokenService.getAccessToken();
      const storedUser = await getUser();

      if (token && storedUser) {
        setIsAuthenticated(true);
        setUser(storedUser);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      } else {
        setIsAuthenticated(false);
        setUser(null);
      }
      setLoading(false);
    };

    initializeAuth();
  }, []);

  const loginHandler = async (username: string, password: string) => {
    const success = await login(username, password);
    if (success) {
      const loggedInUser = await getUser();
      setIsAuthenticated(true);
      setUser(loggedInUser);
      axios.defaults.headers.common['Authorization'] = `Bearer ${TokenService.getAccessToken()}`;
    }
    return success;
  };

  const logoutHandler = () => {
    logout();
    setIsAuthenticated(false);
    setUser(null);
    delete axios.defaults.headers.common['Authorization'];
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, loading, login: loginHandler, logout: logoutHandler }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
