import React from 'react';
import { TbFileTypeXml } from 'react-icons/tb';
import ExportButton from './ExportButton';

interface XmlExportButtonProps {
    filename: string;
    data: {
        transactionId: string;
        cdrSignedValue: string;
        cdrPublicKey: string;
    };
}

const XmlExportButton: React.FC<XmlExportButtonProps> = ({ filename, data }) => {
    const generateXml = () => {
        return `<values>
    <value transactionId="${data.transactionId}" context="Transaction.End">
        <signedData format="OCMF" encoding="plain">
            ${data.cdrSignedValue}
        </signedData>
        <publicKey encoding="plain">
            ${data.cdrPublicKey}
        </publicKey>
    </value>
</values>`;
    };

    const infoText = 'This button allows you to export the transaction data in XML format.';

    return (
        <ExportButton
            filename={filename}
            icon={TbFileTypeXml}
            onGenerateFile={generateXml}
            mimeType="text/xml"
            infoText={infoText}
        />
    );
};

export default XmlExportButton;
