import React, { useEffect, useState } from 'react';
import Button, { ButtonVariant } from '../buttons/Button';
import LinkButton from '../buttons/LinkButton';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { isDarkMode } from '../../utils/utils';

export interface CardProps {
    title?: string;
    description?: string;
    href?: string;
    buttonText?: string;
    buttonOnClick?: () => void;
    buttonVariant?: ButtonVariant;
    children?: React.ReactNode;
    isAuthenticated?: boolean;
    requireAuth?: boolean;
    loading?: boolean;
    exportButton?: React.ReactNode;
    fullWidth?: boolean;
    titleFontSize?: string;
    showStatusIcon?: boolean;
    status?: boolean;
    alignment?: 'left' | 'center';
    hoverEffect?: 'default' | 'card';
}

const Card: React.FC<CardProps> = ({
    title,
    description,
    href,
    buttonText,
    buttonOnClick,
    buttonVariant,
    children,
    isAuthenticated,
    requireAuth,
    loading,
    exportButton,
    fullWidth = false,
    titleFontSize = 'text-base',
    status,
    alignment = 'left',
    hoverEffect = 'default'
}) => {
    const [darkMode, setDarkMode] = useState(isDarkMode());

    useEffect(() => {
        const handleDarkModeChange = (e: MediaQueryListEvent) => {
            setDarkMode(e.matches);
        };

        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        darkModeMediaQuery.addEventListener('change', handleDarkModeChange);

        return () => {
            darkModeMediaQuery.removeEventListener('change', handleDarkModeChange);
        };
    }, []);

    const titleStyle = titleFontSize ? { fontSize: titleFontSize } : {};
    const alignmentClass = alignment === 'center' ? 'text-center' : 'text-left';
    const statusLabel = status ? 'Available' : 'Charging';
    const statusColor = status ? 'rgba(68, 214, 44, 1)' : 'rgba(9, 170, 205, 1)';
    const shadowColor = status ? 'rgba(68, 214, 44, 0.5)' : 'rgba(9, 170, 205, 0.5)';

    const handleMouseMove = (e: React.MouseEvent) => {
        const card = e.currentTarget as HTMLElement;
        const rect = card.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;
        const rotateX = ((centerY - y) / rect.height) * 10;
        const rotateY = ((x - centerX) / rect.width) * 10;

        card.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
        card.style.transformOrigin = `${x}px ${y}px`;
    };

    const handleMouseLeave = (e: React.MouseEvent) => {
        const card = e.currentTarget as HTMLElement;
        card.style.transform = `rotateX(0deg) rotateY(0deg)`;
        card.style.transformOrigin = `center center`;
    };

    const darkModeClass = darkMode ? 'dark' : '';

    const cardContent = (
        <div className="flex flex-col flex-grow w-full card-content relative">
            <div className={`flex flex-col flex-grow ${alignmentClass}`}>
                <div className={`flex ${alignment === 'center' ? 'justify-center' : 'justify-between'} w-full`}>
                    {loading ? (
                        <Skeleton height={30} width={200} className="mb-2" />
                    ) : (
                        href && hoverEffect !== 'card' ? (
                            <Link to={href}>
                                <h5 className={`mb-2 font-body font-semibold tracking-tight text-txt-lg-header hover:text-enerando-green dark:text-txt-dk-header ${alignmentClass}`} style={titleStyle}>
                                    {title}
                                </h5>
                            </Link>
                        ) : (
                            <h5 className={`mb-2 font-body font-semibold tracking-tight text-txt-lg-header dark:text-txt-dk-header ${alignmentClass}`} style={titleStyle}>
                                {title}
                            </h5>
                        )
                    )}
                    {exportButton && (
                        <div className="ml-2">
                            {exportButton}
                        </div>
                    )}
                </div>
                {loading ? (
                    <Skeleton count={3} />
                ) : (
                    <p className={`mb-3 font-body text-txt-lg-body dark:text-txt-dk-body text-sm ${alignmentClass}`}>{description}</p>
                )}
                {requireAuth && !isAuthenticated ? (
                    <p className={`mb-3 font-body text-txt-lg-body dark:text-txt-dk-body text-sm ${alignmentClass}`}>You need to log in to see this content.</p>
                ) : (
                    <div className="flex-grow">{children}</div>
                )}
            </div>
            {buttonText && (
                <div className="mt-4 flex justify-end">
                    {loading ? (
                        <Skeleton height={36} width={100} />
                    ) : (
                        href && hoverEffect !== 'card' ? (
                            <LinkButton to={href} text={buttonText} variant={buttonVariant} />
                        ) : (
                            <Button text={buttonText} onClick={buttonOnClick} variant={buttonVariant} />
                        )
                    )}
                </div>
            )}
        </div>
    );

    const renderCard = () => (
        <div
            className={`p-6 rounded-2xl flex flex-col justify-between h-full w-full ${fullWidth ? 'w-full' : 'max-w-md'} hover-effect-card card-container ${status ? 'static-outline' : 'pulsating-outline'} hover:bg-hover ${darkModeClass} relative ${hoverEffect === 'card' ? 'shadow-custom' : 'shadow-sm'}`}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            style={{ boxShadow: hoverEffect === 'card' ? `0 4px 6px ${shadowColor}` : undefined, backgroundColor: hoverEffect === 'card' ? undefined : (darkMode ? 'var(--bg-dk-bg)' : 'var(--bg-lg-bg)') }} // Added backgroundColor condition
        >
            <div className="status-label" style={{ color: statusColor }}>{statusLabel}</div>
            {cardContent}
        </div>
    );

    if (hoverEffect === 'card') {
        return href ? (
            <Link to={href} className="block h-full w-full">
                {renderCard()}
            </Link>
        ) : (
            renderCard()
        );
    }

    return (
        <div className={`p-6 bg-bg-lg-content rounded-2xl dark:bg-bg-dk-content flex flex-col justify-between h-full w-full ${fullWidth ? 'w-full' : 'max-w-md'} shadow-sm`}>
            {cardContent}
        </div>
    );
};

export default Card;
