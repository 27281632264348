import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useChargepointDetails from '../hooks/useChargepointDetails';
// components
import Heading from '../components/typography/Heading';
import LeafletMap from '../components/Map';
import Timeline from '../components/Timeline';
import CardGrid, { CardItem, CardType } from '../components/positioning/CardGrid';
import { UUID } from 'crypto';

const StationPage: React.FC = () => {
    const { id } = useParams<{ id: UUID }>();
    const { chargepointDetails, error, loading } = useChargepointDetails(id!);
    const { t } = useTranslation();

    if (error) {
        return <div>Error: {error}</div>;
    }

    const chargepoint = chargepointDetails?.chargepoint;

    const locations = chargepoint ? [
        {
            id: 1,
            name: chargepoint.address,
            position: [chargepoint.loc[1], chargepoint.loc[0]] as [number, number]
        }
    ] : [];

    const center: [number, number] = [
        chargepoint?.loc[1] ?? 48.1351,
        chargepoint?.loc[0] ?? 11.5820
    ];

    const attributeCards: CardItem[] = chargepoint ? [
        // fix the null vs. undefined
        { type: CardType.Attribute, value: chargepoint.address ?? undefined, name: t('address'), loading },
        { type: CardType.Attribute, value: chargepoint.business_hours ?? undefined, name: t('business hours'), loading },
        { type: CardType.Attribute, value: chargepoint.operator ?? undefined, name: t('operator'), loading },
    ] : [];

    return (
        <div>
            <Heading 
                title={t(chargepoint?.desc ?? '')} 
                highlight={t('chargepoint')} 
                description={t('')}
            />
            <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">
                <div className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-8 min-h-full">
                    <div className="w-full md:w-1/2 flex-1">
                        <CardGrid items={attributeCards} />
                    </div>
                    <div className="w-full md:w-1/2 flex-1">
                        {chargepointDetails?.chargings && (
                            <Timeline sessions={chargepointDetails.chargings} />
                        )}
                    </div>
                </div>
                <div className="mt-20">
                    <LeafletMap locations={locations} center={center} loading={loading} />
                </div>
            </div>
        </div>
    );
};

export default StationPage;
