import React from 'react';
import { FaBars } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import UserMenu from './UserMenu';
import { useAuth } from '../../contexts/AuthContext';

type NavbarProps = {
    lightLogo: string;
    darkLogo: string;
    brandLink: string;
    menuItems: { label: string; link: string }[];
    toggleSidebar: () => void;
    loading: boolean;
    windowWidth: number; // Add windowWidth prop
};

const Navbar: React.FC<NavbarProps> = ({ lightLogo, darkLogo, brandLink, menuItems, toggleSidebar, loading, windowWidth }) => {
    const { isAuthenticated, logout } = useAuth();

    const showLogo = windowWidth >= 768; // Adjust the width as necessary

    return (
        <nav className="fixed top-0 z-50 w-full bg-bg-lg-content shadow-sm rounded-2xl dark:bg-bg-dk-content">
            <div className="px-3 py-3 lg:px-5 lg:pl-3">
                <div className="flex items-center justify-between">
                    <div className="flex items-center justify-start rtl:justify-end">
                        
                        {/* Sidebar Toggle Button */}
                        <button
                            onClick={toggleSidebar}
                            aria-controls="logo-sidebar"
                            type="button"
                            className="inline-flex items-center p-2 text-icon-lg dark:text-icon-dk rounded-xl hover:text-enerando-green"
                        >
                            <FaBars className="w-5 h-5" aria-hidden="true" />
                            <span className="sr-only">Toggle sidebar</span>
                        </button>

                        {/* Logo */}
                        {showLogo && (
                            <>
                                {loading ? (
                                    <Skeleton height={32} width={100} className="mr-3 dark:hidden" />
                                ) : (
                                    brandLink ? (
                                        <Link to={brandLink}>
                                            <img src={lightLogo} className="h-8 me-3 dark:hidden" alt="ENERANDO Logo" />
                                        </Link>
                                    ) : (
                                        <img src={lightLogo} className="h-8 me-3 dark:hidden" alt="ENERANDO Logo" />
                                    )
                                )}
                                {loading ? (
                                    <Skeleton height={32} width={100} className="hidden dark:block" />
                                ) : (
                                    <img src={darkLogo} className="h-8 me-3 hidden dark:block" alt="ENERANDO Logo" />
                                )}
                            </>
                        )}
                    </div>
                    
                    {/* User Menu */}
                    <div className="flex items-center">
                        {loading ? (
                            Array(3).fill(0).map((_, index) => (
                                <Skeleton key={index} height={32} width={80} className="mx-2" />
                            ))
                        ) : (
                            menuItems.map((item, index) => (
                                <a key={index} href={item.link} className="font-body font-normal text-txt-lg-header dark:text-txt-dk-header mx-2 hover:text-enerando-green">
                                    {item.label}
                                </a>
                            ))
                        )}
                        
                        {!loading && <UserMenu isAuthenticated={isAuthenticated} onLogout={logout} />}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
