import React from 'react';
import { MenuItem } from './types';
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useTranslation } from 'react-i18next';

interface SidebarProps {
    menuItems: MenuItem[];
    sidebarOpen: boolean;
    loading: boolean;
    navbarHeight: number;
    gapSize: number;
}

const Sidebar: React.FC<SidebarProps> = ({ menuItems, sidebarOpen, loading, navbarHeight, gapSize }) => {
    const { t } = useTranslation();

    return (
        <aside
            className={`fixed left-0 z-40 w-64 h-[calc(100vh-${navbarHeight}px-2*${gapSize}px)] mt-${gapSize}px transition-transform duration-300 ${
                sidebarOpen ? 'translate-x-0' : '-translate-x-full'
            } bg-bg-lg-content dark:bg-bg-dk-content rounded-2xl`}
            aria-label="Sidebar"
            style={{ top: navbarHeight + gapSize, bottom: gapSize }}
        >
            <div className="h-full flex flex-col justify-between bg-bg-lg-content dark:bg-bg-dk-content rounded-2xl">
                <div className="px-3 pt-5 pb-5 overflow-y-auto flex-grow">
                    <ul className="space-y-2 font-body">
                        {loading ? (
                            // Display skeletons while loading
                            Array(5).fill(0).map((_, index) => (
                                <li key={index}>
                                    <Skeleton height={40} width="100%" />
                                </li>
                            ))
                        ) : (
                            menuItems.map((item, index) => (
                                <li key={index}>
                                    <NavLink
                                        to={item.link}
                                        className={({ isActive }) =>
                                            `flex items-center p-2 rounded-2xl group ${
                                                isActive
                                                    ? 'text-enerando-blue dark:text-txt-dk-body font-semibold'
                                                    : 'text-enerando-gray dark:text-txt-dk-placeholder font-normal'
                                            } font-body hover:bg-hover-lg dark:hover:bg-hover-dk`
                                        }
                                    >
                                        {item.icon}
                                        <span className="ml-3">{item.label}</span>
                                    </NavLink>
                                </li>
                            ))
                        )}
                    </ul>
                </div>
                <footer className="p-4 bg-bg-lg-content border-t border-border-lg dark:bg-bg-dk-content dark:border-border-dk rounded-b-2xl">
                    <div className="flex flex-col items-center justify-center">
                        <a href="https://www.enerando.energy/" className="text-sm text-gray-500 dark:text-gray-400 hover:underline">
                            © {new Date().getFullYear()} ENERANDO GmbH
                        </a>
                        <ul className="flex justify-center items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                            <li className="mx-2">
                                <a href="https://www.enerando.energy/rechtliches-impressum/datenschutz#impressum" className="hover:underline">{t("legal notice")}</a>
                            </li>
                            <li className="mx-2">
                                <a href="https://www.enerando.energy/rechtliches-impressum/datenschutz" className="hover:underline">{t("privacy policy")}</a>
                            </li>
                        </ul>
                    </div>
                </footer>
            </div>
        </aside>
    );
};

export default Sidebar;
