import React, { useState, useRef, useEffect, ReactNode } from 'react';

interface DropdownProps {
    trigger: ReactNode;
    children: ReactNode;
    delay?: number;
}

const Dropdown: React.FC<DropdownProps> = ({ trigger, children, delay = 500 }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const timeoutRef = useRef<number | null>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleMouseEnter = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = window.setTimeout(() => {
            setMenuOpen(true);
        }, delay);
    };

    const handleMouseLeave = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = window.setTimeout(() => {
            setMenuOpen(false);
        }, delay);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const handleClick = () => {
        setMenuOpen(prev => !prev);
    };

    return (
        <div
            className="relative"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={dropdownRef}
        >
            <div onClick={handleClick}>
                {trigger}
            </div>
            {menuOpen && (
                <div className="font-body font-normal absolute right-0 z-50 mt-2 w-44 bg-bg-lg-item divide-y divide-bg-lg-content rounded-2xl shadow dark:bg-bg-dk-item dark:divide-bg-dk-content">
                    {children}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
