import React from 'react';

interface HeadingProps {
    title: string;
    highlight: string;
    description: string;
}

const Heading: React.FC<HeadingProps> = ({ title, highlight, description }) => {
    return (
        <div>
            <h1 className="mb-4 text-3xl font-heading font-normal text-txt-lg-header dark:text-txt-dk-header md:text-5xl lg:text-6xl uppercase">
                <span className="text-enerando-green">{highlight}</span> {title}
            </h1>
            <p className="text-lg font-body font-normal text-txt-lg-body lg:text-xl dark:text-txt-dk-body">
                {description}
            </p>
        </div>
    );
};

export default Heading;
