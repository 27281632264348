import React from 'react';

interface ButtonGroupProps {
  viewCompleted: boolean;
  names: [string, string];
  setViewCompleted: (viewCompleted: boolean) => void;
}

// can change to make n number of buttons
const ButtonGroup: React.FC<ButtonGroupProps> = ({ viewCompleted, names, setViewCompleted }) => {
  return (
    <div className="inline-flex rounded-2xl shadow-sm">
      <button
        onClick={() => setViewCompleted(false)}
        className={`px-4 py-2 text-sm font-medium ${
          !viewCompleted ? 'bg-enerando-green text-white dark:bg-enerando-green' : 'text-txt-lg-body bg-bg-lg-content dark:bg-bg-dk-item dark:text-txt-dk-body'
        } border border-border-lg rounded-l-lg hover:bg-hover-lg hover:text-txt-lg-placeholder dark:hover:text-txt-dk-placeholder dark:hover:bg-hover-dk dark:border-border-dk`}
      >
        {names[0]}
      </button>
      <button
        onClick={() => setViewCompleted(true)}
        className={`px-4 py-2 text-sm font-medium ${
          viewCompleted ? 'bg-enerando-green text-white dark:bg-enerando-green' : 'text-txt-lg-body bg-bg-lg-content dark:bg-bg-dk-item dark:text-txt-dk-body'
        } border border-border-lg rounded-r-lg hover:bg-hover-lg hover:text-txt-lg-placeholder dark:hover:text-txt-dk-placeholder dark:hover:bg-hover-dk dark:border-border-dk`}
      >
        {names[1]}
      </button>
    </div>
  );
};

export default ButtonGroup;
