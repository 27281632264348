import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// hooks & utils
import useChargingDetails from '../hooks/useChargingDetails';
import useDarkMode from '../hooks/useDarkMode';
import { calculateDuration } from '../utils/utils';
// components
import Heading from '../components/typography/Heading';
import CardGrid, { CardItem, Alignment, HoverEffect, CardType } from '../components/positioning/CardGrid';
import StatusIndicator from '../components/StatusIndicator';
import SessionTime from '../components/SessionTime';
import LeafletMap from '../components/Map';
import Card from '../components/basics/Card';
import ThisJSONTree from '../components/JSONTree';
import CollapsedContent from '../components/CollapsedContent';
import XmlExportButton from '../components/buttons/XmlExportButton'; 
import PdfExportButton from '../components/buttons/PdfExportButton'; 
import Receipt from '../components/Receipt';
// icons
import { BsFillLightningChargeFill } from "react-icons/bs";
import { FaMoneyBills } from "react-icons/fa6";
import { IoTimer } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { UUID } from 'crypto';


const ChargingSessionDetailsPage: React.FC = () => {
    const { id } = useParams<{ id: UUID }>();
    const { chargingDetails, cdrDetails, error, loading } = useChargingDetails(id!);
    const [duration, setDuration] = useState<string>("00:00:00");
    const { t } = useTranslation();
    const isDarkMode = useDarkMode(); // for json tree

    useEffect(() => {
        if (chargingDetails && chargingDetails.start) {
            const startTime = chargingDetails.start;
            let stopTime = chargingDetails.state === 'charging' ? new Date().toISOString() : chargingDetails.stop || new Date().toISOString();

            setDuration(calculateDuration(startTime, stopTime));

            if (chargingDetails.state === 'charging') {
                const intervalId = setInterval(() => {
                    stopTime = new Date().toISOString();
                    setDuration(calculateDuration(startTime, stopTime));
                }, 1000);

                return () => clearInterval(intervalId);
            }
        }
    }, [chargingDetails]);

    if (error) {
        return <div>{error}</div>;
    }

    const locations = chargingDetails ? [
        {
            id: 1,
            name: chargingDetails.address,
            position: [chargingDetails.loc[1], chargingDetails.loc[0]] as [number, number]
        }
    ] : [];

    const center: [number, number] = [
        chargingDetails?.loc[1] ?? 48.1351,
        chargingDetails?.loc[0] ?? 11.5820
      ];

    const attributeItems: CardItem[] = [
        {
            type: CardType.Default,
            fullWidth: true,
            alignment: Alignment.Center,
            children: (
                <SessionTime
                    startTime={chargingDetails?.start ? new Date(chargingDetails.start) : undefined}
                    endTime={chargingDetails?.stop ? new Date(chargingDetails.stop) : undefined}
                    status={chargingDetails?.state ?? ''}
                    loading={loading}
                />

            ),
            loading: loading
        },
        {
            type: CardType.Default,
            fullWidth: true,
            alignment: Alignment.Center,
            children: (
                <StatusIndicator
                        state={chargingDetails?.state ?? ''}
                        status={chargingDetails?.status ?? ''}
                        loading={loading}
                    />

            ),
            loading: loading
        },
        {
            type: CardType.Attribute,
            value: `${chargingDetails ? chargingDetails.address : ''}`,
            name: t('location'),
            icon: <FaLocationDot className="mb-2 text-2xl text-enerando-green" />,
            loading: loading,
        },
        {
            type: CardType.Attribute,
            value: `${chargingDetails ? chargingDetails.energy : ''}`,
            name: t('charged') + ' ' +  t('energy') + ' (kWh)',
            icon: <BsFillLightningChargeFill className="w-5 h-5 transition duration-75 text-enerando-green" />,
            loading: loading,
        },
        {
            type: CardType.Attribute,
            value: duration,
            name: t('duration'),
            icon: <IoTimer className="w-5 h-5 transition duration-75 text-enerando-green" />,
            loading: loading,
        },
        {
            type: CardType.Attribute,
            value: chargingDetails ? chargingDetails.cost.toString() : '',
            name: t('cost'),
            icon: <FaMoneyBills className="w-5 h-5 transition duration-75 text-enerando-green" />,
            loading: loading,
        },
    ];

    const parseCdrSignedValue = (cdrSignedValue: string) => {
        try {
            const parts = cdrSignedValue.split('|');
            if (parts.length === 3) {
                const jsonPart = JSON.parse(parts[1]);
                return jsonPart;
            }
            return null;
        } catch (e) {
            console.error('Failed to parse CDR signed value:', e);
            return null;
        }
    };

    const signedValJson = cdrDetails?.cdr_signed_value ? parseCdrSignedValue(cdrDetails.cdr_signed_value) : null;
    const pubKeyJson = cdrDetails?.cdr_public_key ? cdrDetails.cdr_public_key : null;

    const receiptData = {
        transactionId: chargingDetails?.uuid ?? '',
        period: `${chargingDetails?.start ?? ''} - ${chargingDetails?.stop ?? ''}`,
        energy: chargingDetails?.energy ?? 0,
        time: duration,
        tariff: cdrDetails?.cdr_energy_tariff ?? 0
    };

    return (
        <div>
            <Heading 
                title={t('session')} 
                highlight={t('charging')} 
                description={t('')}
            />
            
            <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">
                <div className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-8 min-h-full">
                    <div className="w-full md:w-1/2 flex flex-col space-y-4 flex-1">
                        <CardGrid items={attributeItems} />
                    </div>
                    <div className="w-full md:w-1/2 flex-1">
                        <LeafletMap locations={locations} center={center} loading={loading}/>
                    </div>
                </div>

                <div className="mt-10 flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-8 min-h-full">
                {signedValJson && pubKeyJson && (
                    <div className="w-full md:w-1/2 flex flex-col space-y-4 flex-1">
                        <Card 
                            title={t('Receipt')}
                            loading={loading}
                            fullWidth={true}
                            exportButton={
                                <PdfExportButton
                                    filename="Receipt.pdf"
                                    data={receiptData}
                                />
                            }
                        >
                            <Receipt 
                                transactionId={receiptData.transactionId}
                                period={receiptData.period}
                                energy={receiptData.energy}
                                time={receiptData.time}
                                tariff={receiptData.tariff}
                            />
                        </Card>
                    </div>
                )}
                {signedValJson && pubKeyJson && (
                    <div className="w-full md:w-1/2 flex flex-col space-y-4 flex-1">
                        <Card 
                            title={t('Transparancy')} 
                            loading={loading}
                            fullWidth={true}
                            exportButton= {
                                id && cdrDetails?.cdr_signed_value && cdrDetails?.cdr_public_key && (
                                    <XmlExportButton
                                    filename="Enerando_session_id.xml" // add session id here
                                    data={{
                                        transactionId: id, 
                                        cdrSignedValue: cdrDetails.cdr_signed_value,
                                        cdrPublicKey: cdrDetails.cdr_public_key
                                    }}
                                />
                                )
                            }
                        >
                            <CollapsedContent title={t('Signed Meter Data')}>
                                <ThisJSONTree data={signedValJson} invertTheme={!isDarkMode} />
                            </CollapsedContent>
                            <CollapsedContent title={t('Public Key')}>
                                <ThisJSONTree data={pubKeyJson} invertTheme={!isDarkMode} />
                            </CollapsedContent>
                        </Card>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChargingSessionDetailsPage;
