import axios from 'axios';
import { UUID } from 'crypto';
import { Paths } from '../api/client';

export const API_BASE_URL = 'https://api.testing.enerando.systems';

const getToken = (): string | null => {
  return localStorage.getItem('accessToken');
};

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const getChargepoints = async (): Promise<Paths.SitesChargepointsList.Responses.$200> => {
  const response = await axiosInstance.get('/api/v2/sites/chargepoints');
  return response.data;
};

export const getChargepointDetails = async (id: UUID): Promise<Paths.SitesChargepointsRetrieve.Responses.$200> => {
  const response = await axiosInstance.get(`/api/v2/sites/chargepoints/${id}`);
  return response.data;
};

export const getChargingSessions = async (): Promise<Paths.SessionList.Responses.$200> => {
  const response = await axiosInstance.get('/api/v2/sessions');
  return response.data;
};

export const getChargingDetails = async (id: UUID): Promise<Paths.SessionDetails.Responses.$200> => {
  const response = await axiosInstance.get(`/api/v2/sessions/${id}`);
  return response.data;
};

export const getSiteList = async (): Promise<Paths.SitesList.Responses.$200> => {
  const response = await axiosInstance.get(`/api/v2/sites`);
  return response.data;
}

export const getCardsList = async (): Promise<Paths.CardsList.Responses.$200> => {
  const response = await axiosInstance.get(`/api/v2/cards`);
  return response.data;
}

export const getUserList = async (): Promise<Paths.UsersList.Responses.$200> => {
  const response = await axiosInstance.get(`/api/v2/users`);
  return response.data;
}
