import { useTranslation } from 'react-i18next';
import useCardsList from '../hooks/useCardslist';

// components
import Heading from '../components/typography/Heading';
import Table, { TableColumn, TableRow } from '../components/basics/table';


const CardsPage: React.FC = () => {
    const { cards, error, loading } = useCardsList();
    const { t } = useTranslation();

    const cardColumns: TableColumn[] = [
        { header: t("tenant"), key: "tenant" },
        { header: t("user"), key: "user" },
        { header: t("number"), key: "number" },
        { header: t("description"), key: "desc" },
        { header: t("status"), key: "enabled" },
    ]

    // change to actual error component
    if (error) {
        return <div>{error}</div>;
    }

    const tableData: TableRow[] = cards.map(card => ({
        tenant: card.user?.tenant?.name || 'N/A',
        number: card.number,
        user: card.user?.email || 'N/A',
        desc: card.desc || '',
        enabled: card.enabled ? "Active" : "Inactive"
    }));

    return (
        <div>
            <Heading 
                title={t('Cards')} 
                highlight={t('charging')} 
                description={t('All the Charging Cards.')}
            />
            <div className="main-content mt-10 text-txt-lg-body dark:text-txt-dk-body">

                <Table columns={cardColumns} data={tableData} loading={loading}/>

            </div>
        </div>
    );
};

export default CardsPage;