import { useState, useEffect } from "react";
import { getSiteList } from "../services/apiService";
import { Components } from "../api/client";

type Site = Components.Schemas.Site;

const useSites = () => {
    const [sites, setSites] = useState<Site[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data: Site[] = await getSiteList();
                console.log('Fetched data:', data);
                setSites(data);
            } catch (error) {
                setError('Failed to fetch sites.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { sites, error, loading };
};

export default useSites;